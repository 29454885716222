<script setup lang="ts">

import { useConfigStore } from '@/stores/manageConfig';

defineOptions({ name: 'LoginForm' });

import { QLoading } from '@/utils/QLoading';
import { QNotify } from '@/utils/QNotify';
import * as authUtil from '@/utils/auth';
import * as LoginApi from '@/api/login';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import { delay } from '@/utils';

import { cloneDeep } from 'lodash-es';
import { debounce } from 'quasar';
import LoginPanel from './LoginPanel.vue';
import SignUpPanel from './SignUpPanel.vue';

const { currentRoute, push } = useRouter();
const redirect = ref<string>('');
const loading = ref(false);

const SUBMIT_TYPE = {
  LOGIN: 'login',
  REGISTER: 'register'
};

const isLogin = ref(true)

const submitType = ref();
const formRef = ref();
const verifyRef = ref();
const showCaptcha = ref(false);
const captchaSize = ref();
// const captchaType = ref('blockPuzzle'); // blockPuzzle 滑块 clickWord 点击文字
const captchaType = ref('clickWord'); // blockPuzzle 滑块 clickWord 点击文字

const { t } = useI18n();

const loginData = reactive({
  isShowPassword: false,
  captchaEnable: import.meta.env.VITE_APP_CAPTCHA_ENABLE,
  tenantEnable: import.meta.env.VITE_APP_TENANT_ENABLE,
  loginForm: {
    // tenantName: 'tsui',
    mobile: '',
    password: '',
    // mobile: '19988889999',
    // password: '123456',
    captchaVerification: '',
    bindCode: currentRoute.value?.query?.code?.toString(),
    rememberMe: false // 是否记录我
  }
});

if (process.env.NODE_ENV === 'development') {
  // loginData.loginForm.mobile = '19988889999';
  // loginData.loginForm.password = '123456';
  loginData.loginForm.rememberMe = true;
}

const { getPhoneDigit } = toRefs(useConfigStore());

const phoneRet = (val) => {
  const digitLength = getPhoneDigit.value;
  let reg = new RegExp(`^\\d{${digitLength}}$`);
  // if (submitType.value != SUBMIT_TYPE.REGISTER) {
  //   reg = /^\d+$/; // 非注册时只校验纯数字
  // }
  return reg.test(val);
};

const generationPhoneMask = () => {
  const digitLength = getPhoneDigit.value;
  // 生成digitLength长度的‘#’
  const mask = new Array(digitLength).fill('#').join('');
  // console.log(mask);
  formMasks.phoneNumber = mask;
};

const formMasks = {
  phoneNumber: ''
};

generationPhoneMask();

const formRules = {
  phoneNumber: [
    val => !!val || t('login.rules.noPhoneError'),
    val => phoneRet(val) || t('login.rules.phoneNumberError')
  ],
  password: [
    val => !!val || t('login.rules.noPasswordError'),
    val => (val.length >= 6 && val.length <= 20) || t('login.rules.passwordError')
  ]
};

// 获取租户 ID
// const getTenantId = async () => {
//   if (loginData.tenantEnable === 'true') {
//     const res = await LoginApi.getTenantIdByName(loginData.loginForm.tenantName);
//     authUtil.setTenantId(res);
//   }
// };

const needVerify = ref(false);

const login = (data) => {
  Object.assign(loginData, data)
  setSubmitType(SUBMIT_TYPE.LOGIN)
}

const signup = (data) => {
  loginData.loginForm = {
    ...loginData.loginForm,
    ...data.loginForm
  }
  // Object.assign(loginData, data)
  setSubmitType(SUBMIT_TYPE.REGISTER)
}

const setSubmitType = async (type: string) => {
  // console.log('setSubmitType', type);
  submitType.value = type;
};

// 提交表单
const handleSubmit = () => {
  if (loginData.captchaEnable === 'false' || (submitType.value === SUBMIT_TYPE.LOGIN && !needVerify.value)) {
    // console.log('未开启验证码');
    // 进行登录或注册判断
    submitted({});
  } else {
    // 弹出验证码
    verifyRef.value.show();
  }
};

const submitted = async (params) => {
  QLoading.show({
    message: t('common.processing'),
    delay: 100
  });
  loading.value = true;
  try {
    // 获取租户ID
    // await getTenantId();
    // 获取验证码
    loginData.loginForm.captchaVerification = params.captchaVerification;
    // 登录 或 注册
    let res;
    // 根据类型判断是登录还是注册
    switch (submitType.value) {
      case 'login':
        // 登录
        res = await handleLogin();
        break;
      case 'register':
        // 注册
        res = await handleRegister();
        break;
      default:
        QNotify.error('type error');
        return;
    }
    // console.log('res', res);
    if (!res) {
      return;
    }

    // 是否记住我 （预留 暂不需要此功能）
    if (loginData.loginForm.rememberMe) {
      const loginForm = cloneDeep(loginData.loginForm);
      authUtil.setLoginForm(loginForm);
    } else {
      authUtil.removeLoginForm();
    }

    // 设置token
    authUtil.setToken(res);

    if (!redirect.value) {
      redirect.value = '/';
    }
    // 判断是否为SSO登录
    if (redirect.value.indexOf('sso') !== -1) {
      window.location.href = window.location.href.replace('/login?redirect=', '');
    } else {
      // 跳转
      await push({ path: redirect.value });
    }
    needVerify.value = false;
    QNotify.success(t('login.welcome'));
  } finally {
    loading.value = false;
    QLoading.hide();
  }

};

// 登录
const handleLogin = async () => {
  try {
    return await LoginApi.login(loginData.loginForm);
  } catch (error) {
    needVerify.value = true;
    return false;
  }
  // 登录
  // return await LoginApi.login(loginData.loginForm).catch(() => needVerify.value = true);
};

const handleRegister = async () => {
  // 注册 并 登录
  const res = await LoginApi.register(loginData.loginForm);
  if (res) {
    // 注册时延迟1秒，等待钱包等创建完成
    await delay(1000);
  }
  return res;
};

watch(
  () => currentRoute.value,
  (route: RouteLocationNormalizedLoaded) => {
    redirect.value = route?.query?.redirect as string;
    // const userId = route?.query?.bindUserId as unknown as number
    // if (userId) {
    //   loginData.loginForm.bindUserId = userId;
    // }
  },
  {
    immediate: true
  }
);

// region 改变验证框大小
const handleResize = () => {
  // console.log('handleResize', loginData.captchaEnable);
  if (!loginData.captchaEnable) {
    showCaptcha.value = false;
    return;
  }
  // 根据宽度实时改变大小
  const width = document.body.clientWidth;
  // console.log(width);
  captchaSize.value = Math.min(width - 50, 400);
  showCaptcha.value = false;
  nextTick(() => {
    showCaptcha.value = true;
  });
};

handleResize();
// endregion 改变验证框大小

onMounted(() => {
  // getLoginFormCache();
  if (loginData.captchaEnable === 'true') {
    window.addEventListener('resize', debounce(handleResize, 500));
  }
});

onUnmounted(() => {
  if (loginData.captchaEnable === 'true') {
    window.removeEventListener('resize', debounce(handleResize, 500));
  }
});


</script>

<template>
  <q-form class="login-form"
          ref="formRef"
          @submit="handleSubmit"
          autocorrect="off"
          autocapitalize="off"
          autocomplete="off"
          spellcheck="false"
  >
    <q-space class="q-py-lg" />
    <LoginPanel
      v-if="isLogin"
      :form-masks="formMasks"
      :form-rules="formRules"
      @success="login"
      @to-sign-up="isLogin = false"
    />
    <SignUpPanel
      v-else
      :form-masks="formMasks"
      :form-rules="formRules"
      @success="signup"
      @to-login="isLogin = true"
    />
    <!--    &lt;!&ndash;  手机号&ndash;&gt;-->
    <!--    <div class="q-pa-sm login-input">-->
    <!--      <div class="q-pa-sm text-grey-6 text-weight-bold">{{ t('login.phoneNumber') }}</div>-->
    <!--      &lt;!&ndash;               standout="bg-grey-4 text-black" &ndash;&gt;-->
    <!--      <q-input v-model="loginData.loginForm.mobile"-->
    <!--               :placeholder="t('login.phoneNumberPlaceholder')"-->
    <!--               outlined rounded clearable-->
    <!--               :rules="formRules.phoneNumber"-->
    <!--               :mask="formMasks.phoneNumber"-->
    <!--               type="tel"-->
    <!--      >-->
    <!--        <template v-slot:prepend>-->
    <!--          <q-icon name="phone_iphone" />-->
    <!--        </template>-->
    <!--      </q-input>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; 密码框&ndash;&gt;-->
    <!--    <div class="q-pa-sm login-input">-->
    <!--      <div class="q-pa-sm text-grey-6 text-weight-bold">{{ t('login.password') }}</div>-->
    <!--      &lt;!&ndash;               standout="bg-grey-4 text-black"&ndash;&gt;-->
    <!--      <q-input v-model="loginData.loginForm.password"-->
    <!--               :placeholder="t('login.passwordPlaceholder')"-->
    <!--               outlined rounded-->
    <!--               :type="!loginData.isShowPassword ? 'password' : 'text'"-->
    <!--               :rules="formRules.password"-->
    <!--      >-->
    <!--        <template v-slot:prepend>-->
    <!--          <q-icon name="lock_outline" />-->
    <!--        </template>-->
    <!--        <template v-slot:append>-->
    <!--          <q-icon-->
    <!--            :name="!loginData.isShowPassword ? 'visibility_off' : 'visibility'"-->
    <!--            class="cursor-pointer"-->
    <!--            @click="loginData.isShowPassword = !loginData.isShowPassword"-->
    <!--          />-->
    <!--        </template>-->
    <!--      </q-input>-->
    <!--    </div>-->
    <!--    <q-space class="q-py-xl" />-->
    <!--    &lt;!&ndash; 登录按钮&ndash;&gt;-->
    <!--    <div class="q-px-xl q-pt-xl q-pb-md column login-button">-->
    <!--      <q-btn class="q-px-xl q-py-sm"-->
    <!--             color="primary" type="submit"-->
    <!--             :label="t('login.loginBtn')"-->
    <!--             @click="setSubmitType(SUBMIT_TYPE.LOGIN)"-->
    <!--             :loading="loading"-->
    <!--             :disable="loading"-->
    <!--             rounded noCaps-->
    <!--      />-->
    <!--    </div>-->
    <!--    &lt;!&ndash; 注册按钮&ndash;&gt;-->
    <!--    <div class="q-pa-md q-px-xl column sign-up-button">-->
    <!--      <q-btn class="q-px-xl q-py-sm"-->
    <!--             color="primary" type="submit"-->
    <!--             :label="t('login.registerBtn')"-->
    <!--             @click="setSubmitType(SUBMIT_TYPE.REGISTER)"-->
    <!--             :loading="loading"-->
    <!--             :disable="loading"-->
    <!--             rounded noCaps-->
    <!--      />-->
    <!--    </div>-->
    <Verify v-if="showCaptcha"
            ref="verifyRef"
            :captchaType="captchaType"
            :imgSize="{ width: `${captchaSize}px` , height: `${captchaSize / 2}px` }"
            mode="pop"
            @success="submitted"
    />
  </q-form>
</template>

<style lang="scss" scoped>
.login-form {
  .login-input {
    :deep(.q-field--highlighted *) {
      box-shadow: none !important;
      transition: none !important;
      //color: inherit !important;
    }
  }
}
</style>