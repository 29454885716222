<script setup lang="ts">

import * as authUtil from '@/utils/auth';

defineOptions({ name: 'LoginPanel' });

const props = defineProps({
  formRules: {
    type: Object,
    required: true
  },
  formMasks: {
    type: Object,
    required: true
  }
});

const { currentRoute, push } = useRouter();
const redirect = ref<string>('');
const loading = ref(false);

const SUBMIT_TYPE = {
  LOGIN: 'login',
  REGISTER: 'register'
};

const { t } = useI18n();
//
const loginData = reactive({
  isShowPassword: false,
  loginForm: {
    // tenantName: 'tsui',
    mobile: '',
    password: '',
    rememberMe: false // 是否记录我
  }
});

// 记住我
const getLoginFormCache = () => {
  const loginForm = authUtil.getLoginForm();
  if (loginForm) {
    loginData.loginForm = {
      ...loginData.loginForm,
      mobile: loginForm.mobile ? loginForm.mobile : loginData.loginForm.mobile,
      password: loginForm.password ? loginForm.password : loginData.loginForm.password,
      rememberMe: loginForm.rememberMe
      // tenantName: loginForm.tenantName ? loginForm.tenantName : loginData.loginForm.tenantName
    };
  }
};

onMounted(() => {
  getLoginFormCache();
});


const emit = defineEmits(['success', 'toSignUp']);

const handleSuccess = () => {
  emit('success', loginData);
};

const showSignup = () => {
  emit('toSignUp');
};

</script>

<template>
  <!--  手机号-->
  <div class="q-pa-sm login-input">
    <div class="q-pa-sm text-grey-9 text-weight-bold">{{ t('login.phoneNumber') }}</div>
    <!--               standout="bg-grey-4 text-black" -->
    <q-input v-model="loginData.loginForm.mobile"
             :placeholder="t('login.phoneNumberPlaceholder')"
             outlined rounded clearable
             :rules="formRules?.phoneNumber"
             :mask="formMasks?.phoneNumber"
             type="tel"
    >
      <template v-slot:prepend>
        <q-icon name="phone_iphone" />
      </template>
    </q-input>
  </div>
  <!-- 密码框-->
  <div class="q-pa-sm login-input">
    <div class="q-pa-sm text-grey-9 text-weight-bold">{{ t('login.password') }}</div>
    <!--               standout="bg-grey-4 text-black"-->
    <q-input v-model="loginData.loginForm.password"
             :placeholder="t('login.passwordPlaceholder')"
             outlined rounded
             :type="!loginData.isShowPassword ? 'password' : 'text'"
             :rules="formRules?.password"
    >
      <template v-slot:prepend>
        <q-icon name="lock_outline" />
      </template>
      <template v-slot:append>
        <q-icon
          :name="!loginData.isShowPassword ? 'visibility_off' : 'visibility'"
          class="cursor-pointer"
          @click="loginData.isShowPassword = !loginData.isShowPassword"
        />
      </template>
    </q-input>
  </div>
<!--  <q-space class="q-py-xl" />-->
  <!-- 登录按钮-->
  <div class="q-px-xl q-py-lg column login-button">
    <q-btn class="q-px-xl q-py-sm"
           color="primary" type="submit"
           :label="t('login.loginBtn')"
           :loading="loading"
           :disable="loading"
           rounded noCaps
           @click="handleSuccess"
    />
  </div>
  <div class="column items-center"
             @click="showSignup"
  >
    <div class="col row items-center">
      <div v-text="t('login.toSignUp')" class="q-mr-xs text-grey-8">
      </div>
      <q-btn color="primary" class="q-pa-none" style="text-decoration: underline;"
             :label="t('login.signUp')"
             flat noCaps
      />
    </div>

  </div>
</template>

<style lang="scss" scoped>
.login-input {
  :deep(.q-field--highlighted *) {
    box-shadow: none !important;
    transition: none !important;
    //color: inherit !important;
  }
}
</style>